import React, { useState } from 'react';
import styles from './accordian.module.css';
import { CaretDownCircleOutline, CaretUpCircleOutline } from 'react-ionicons';

export default function Accordian({ style, title, children }) {
    const [showDetails, setShowDetails] = useState(false);
    function toggle() {
        setShowDetails(!showDetails);
    }
    return (
        <div
            style={style}
            className={`${styles.container} ${
                showDetails ? styles.expand : ''
            }`}
        >
            <div onClick={() => toggle()} className={styles.title}>
                <div style={{ flex: 1, paddingRight: 8 }}>{title}</div>
                {showDetails ? (
                    <CaretUpCircleOutline />
                ) : (
                    <CaretDownCircleOutline />
                )}
            </div>
            <div className={styles.details}>{children}</div>
        </div>
    );
}
