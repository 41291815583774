import { useEffect, useState } from 'react';
import './App.css';
import Home from 'pages/home/Home';
import { ArrowUp } from 'react-ionicons';

function App() {
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);
    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };

    return (
        <div className="App">
            <Home />
            {showButton && (
                <button onClick={scrollToTop} className="back_to_top">
                    <ArrowUp />
                </button>
            )}
        </div>
    );
}

export default App;
