import React, { useState } from 'react';
import styles from './header.module.css';
import { LogoDiscord, LogoTwitter } from 'react-ionicons';
import { Navbar } from 'components/navigation/Navbar';
import MobileNav, { MobileLinksMenu } from 'components/navigation/MobileNav';
import Heading from 'components/typography/Heading';
import OpenSea from 'assets/images/opensealogo.svg';

export default function Header() {
    const [openMenu, setOpenMenu] = useState(false);
    function toggleMenu() {
        setOpenMenu(!openMenu);
    }
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.topContainer}>
                    <SocialButtons />
                    <div className={styles.center}>
                        <Heading text="Meemo's World" />
                    </div>
                    <Mint />
                    <MobileNav toggleMenu={toggleMenu} openMenu={openMenu} />
                </div>
                <div>
                    <MobileLinksMenu openMenu={openMenu} />
                </div>
                <Navbar />
            </div>
        </div>
    );
}

function Mint() {
    return (
        <div className={styles.mint}>
            SOLD OUT
        </div>
    );
}

const OpenSeaLogo = () => {
  return (
      <span><img src={OpenSea} alt="OpenSea Logo" style={{height: 38}}/></span>
  );
}

export function SocialButtons() {
    return (
        <div className={styles.socialButtonsContainer}>
            <a
                className={styles.socialButton}
                href="https://twitter.com/meemosworld"
                rel="noreferrer"
                target="_blank"
            >
                <LogoTwitter />
            </a>
            <a
                className={styles.socialButton}
                href="https://discord.com/invite/meemosworld"
                rel="noreferrer"
                target="_blank"
            >
                <LogoDiscord/>
            </a>
            <a
                className={styles.socialButton}
                href="https://opensea.io/collection/meemosworld"
                rel="noreferrer"
                target="_blank"
            >
                <OpenSeaLogo/>
            </a>
        </div>
    );
}
