import Heading from 'components/typography/Heading';
import React from 'react';
import styles from '../home.module.css';

export default function Roadmap() {
    return (
        <section id="roadmap" className={`${styles.section} ${styles.roadmap}`}>
            <div className={styles.container}>
                <Heading text="Roadmap" />
                <div className={styles.roadmapContainer}>
                    <PhaseZero />
                    <PhaseOne />
                    <PhaseTwo />
                    <PhaseThree />
                </div>
            </div>
        </section>
    );
}

function PhaseZero() {
    return (
        <div className={styles.mapContainer}>
            <div className={styles.stepContainer}>
                <div className={styles.sideText}>
                    <h3>Phase&nbsp;0</h3>
                </div>
                <div className={styles.mapContentContainer}>
                    <h2>Welcome to Meemo's World</h2>
                    <p className={styles.sideText}>Phase&nbsp;0</p>
                    <ul>
                        <li>
                            Build a community of people with a shared vision to
                            improve awareness and access to mental health
                            solutions.
                        </li>
                        <li>
                            Create a supportive and resourceful platform for
                            mental health education and discourse.
                        </li>
                        <li>
                            Hold workshops, AMAs and panels with mental health
                            professionals.
                        </li>
                        <li>Receive community feedback on our application!</li>
                    </ul>
                    <img
                        className={styles.rightArrow}
                        alt=""
                        src={require('assets/images/phase_0_arrow.png')}
                    />
                </div>
            </div>
            <div className={styles.imageContainer}>
                <img alt="" src={require('assets/meemos/phase0.png')} />
            </div>
        </div>
    );
}

function PhaseOne() {
    return (
        <div className={styles.mapContainer}>
            <div className={styles.imageContainer}>
                <img alt="" src={require('assets/meemos/phase1.png')} />
            </div>
            <div className={styles.stepContainer}>
                <div className={styles.mapContentContainer}>
                    <h2>Release the Meemos</h2>
                    <p className={styles.sideText}>Phase&nbsp;1</p>
                    <ul>
                        <li>Whitelist presale.</li>
                        <li>Official public launch.</li>
                        <li>Launch of our earliest beta application.</li>
                        <li>
                            50% of mint revenue put towards our product
                            development and future research trials.
                        </li>
                        <li>
                            At sell out, 100K USD donation to mental health
                            research specifically in the field of online mental
                            health treatments.
                        </li>
                        <li>
                            The remainder of funds will go towards the team and
                            our community wallet to fund community-focused
                            initiatives.
                        </li>
                    </ul>
                    <img
                        className={styles.leftArrow}
                        alt=""
                        src={require('assets/images/phase_1_arrow.png')}
                    />
                </div>
                <div className={styles.sideText}>
                    <h3>Phase&nbsp;1</h3>
                </div>
            </div>
        </div>
    );
}

function PhaseTwo() {
    return (
        <div className={styles.mapContainer}>
            <div className={styles.stepContainer}>
                <div className={styles.sideText}>
                    <h3>Phase&nbsp;2(Q1&nbsp;2022)</h3>
                </div>
                <div className={styles.mapContentContainer}>
                    <h2>Meemos, Together~</h2>
                    <p className={styles.sideText}>
                        Phase&nbsp;2(Q1&nbsp;2022)
                    </p>
                    <ul>
                        <li>
                            Release of educational and support programs
                            surrounding mental health.
                        </li>
                        <li>
                            Creation of a formal voting process in preparation
                            for future application development and community
                            initiatives.
                        </li>
                        <li>
                            Continued product development based off feedback
                            from beta app launch.
                        </li>
                    </ul>
                    <img
                        className={styles.rightArrow}
                        alt=""
                        src={require('assets/images/phase_2_arrow.png')}
                    />
                </div>
            </div>
            <div className={styles.imageContainer}>
                <img alt="" src={require('assets/meemos/phase2.png')} />
            </div>
        </div>
    );
}

function PhaseThree() {
    return (
        <div
            style={{ justifyContent: 'flex-end' }}
            className={styles.mapContainer}
        >
            <div className={styles.stepContainer}>
                <div className={styles.mapContentContainer}>
                    <h2>Open the Floodgates</h2>
                    <p className={styles.sideText}>
                        Phase&nbsp;3(Q2&nbsp;2022)
                    </p>
                    <ul>
                        <li>
                            Public launch of our application. While NFT holders
                            will maintain lifetime access, new users must pay a
                            subscription fee.
                        </li>
                        <li>
                            Create a launchpad for founders to be able to build
                            community-driven products, to develop a product
                            together truly tailored for the user.
                        </li>
                        <li>
                            Continue value creation for our supporters through
                            voting on community funds and direction.
                        </li>
                    </ul>
                </div>
                <div className={styles.sideText}>
                    <h3>Phase&nbsp;3(Q2&nbsp;2022)</h3>
                </div>
            </div>
        </div>
    );
}
