import React from 'react';
import styles from '../home.module.css';
import Heading from 'components/typography/Heading';
import Accordian from 'components/accordian/Accordian';

export default function Application() {
    return (
        <section id="app" className={`${styles.section} ${styles.app}`}>
            <div className={styles.container}>
                <Heading text="Our Application" />
                <PrototypeGallery />
                <Heading text="App FAQ" />
                <Accordian
                    style={{ marginTop: 24 }}
                    title="How does the app work?"
                >
                    <p>
                        Think about the games you used to play where you had a
                        pet to take care of, evolve and grow. Do you remember
                        how that made you feel? Alongside your mental health
                        journey, you’ll have a companion that develops skills
                        and grows with you. Meemo's World immerses its users in
                        a world where taking care of your mental health is fun!
                    </p>
                    <p>
                        Our app uses cognitive behavioural therapy (CBT),
                        evidence-based therapy that helps individuals identify,
                        evaluate and reframe your thoughts to improve your mood.
                        [1]
                    </p>
                    <p>
                        We build consistency and habits through the use of
                        behavioural science [2], to improve the likelihood of
                        people completing these activities.
                    </p>
                    <i>
                        <p>
                            [1] Beck, A. T. (1993). Cognitive therapy: past,
                            present, and future. Journal of consulting and
                            clinical psychology, 61(2), 194.
                        </p>
                        <p>
                            [2] Mekler, E. D., Brühlmann, F., Tuch, A. N., &
                            Opwis, K. (2017). Towards understanding the effects
                            of individual gamification elements on intrinsic
                            motivation and performance. Computers in Human
                            Behavior, 71, 525-534.
                        </p>
                    </i>
                </Accordian>
                <Accordian title="Who is the team behind the app?">
                    <p>
                        Dr. Dekz has published research in a peer-reviewed
                        journal on the use of online treatments for chronic
                        conditions such as chronic pain, depression and anxiety.
                        Margaret and Ying also sit on our advisory board as
                        certified psychologists to review the application
                        material.
                    </p>
                </Accordian>
                <Accordian title="Why should I use your app over others in the market?">
                    <p>
                        Although thousands of mental health applications
                        currently exist on the market, literature demonstrates
                        low completion and adherence rates for these apps. This
                        is because [1]:
                    </p>
                    <ol>
                        <li>
                            Apps are not user-centric and are not tailored for
                            users,
                        </li>
                        <li>A lack of user trust, and </li>
                        <li>A lack of accountability in completing tasks.</li>
                    </ol>
                    <p></p>
                    <p>
                        We have identified 6 key pillars (sleep, exercise, diet,
                        mental resilience, relationships, purpose) important in
                        improving your mental health through gradual but
                        sustainable change [2].
                    </p>
                    <p>
                        By being community led, we are able to design our app by
                        incorporating features that have been suggested directly
                        from our community to create a user-centric experience.
                    </p>
                    <i>
                        <p>
                            [1] Patoz, M. C., Hidalgo-Mazzei, D., Pereira, B.,
                            Blanc, O., de Chazeron, I., Murru, A., ... &
                            Samalin, L. (2021). Patients’ adherence to
                            smartphone apps in the management of bipolar
                            disorder: a systematic review. International journal
                            of bipolar disorders, 9(1), 1-15.
                        </p>
                        <p>
                            [2] Malhi, G. S., Bell, E., Bassett, D., Boyce, P.,
                            Bryant, R., Hazell, P., ... & Murray, G. (2021). The
                            2020 Royal Australian and New Zealand College of
                            Psychiatrists clinical practice guidelines for mood
                            disorders. Australian & New Zealand Journal of
                            Psychiatry, 55(1), 7-117.
                        </p>
                    </i>
                </Accordian>
            </div>
        </section>
    );
}

const appImages = [
    {
        image: require('assets/app/profile.png'),
        alt: ''
    },
    {
        image: require('assets/app/goals.png'),
        alt: ''
    },
    {
        image: require('assets/app/egg.png'),
        alt: ''
    },
    {
        image: require('assets/app/hatch.png'),
        alt: ''
    }
];
function PrototypeGallery() {
    return (
        <div className={styles.gallery}>
            {appImages.map(({ alt, image }, i) => {
                return <img key={i} alt={alt} src={image} />;
            })}
        </div>
    );
}
