import React from 'react';
import styles from './navigation.module.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
    MapOutline,
    PeopleOutline,
    DocumentTextOutline,
    SunnyOutline
} from 'react-ionicons';

const links = [
    {
        type: 'anchor',
        name: 'App',
        href: '#app',
        icon: <SunnyOutline />
    },
    {
        type: 'anchor',
        name: 'Roadmap',
        href: '#roadmap',
        icon: <MapOutline />
    },
    {
        type: 'anchor',
        name: 'Team',
        href: '#team',
        icon: <PeopleOutline />
    },
    {
        type: 'external',
        name: 'Litepaper',
        href: 'https://mammoth-age-e87.notion.site/Meemo-s-World-edbe30c750e54d01b32cc39663e5e35f',
        icon: <DocumentTextOutline />
    }
];

export function Navbar() {
    return (
        <div className={styles.container}>
            <Links />
        </div>
    );
}

export const Links = () => {
    return links.map((link, i) => {
        return (
            <NavLink
                key={i}
                type={link.type}
                name={link.name}
                href={link.href}
                Icon={() => link.icon}
            />
        );
    });
};

export function NavLink({ type, Icon, href, name }) {
    switch (type) {
        case 'anchor':
            return (
                <AnchorLink className={styles.link} href={href}>
                    <Icon /> {name}
                </AnchorLink>
            );
        default:
            return (
                <a
                    title=""
                    className={styles.link}
                    rel="noreferrer"
                    target="_blank"
                    href={href}
                >
                    <Icon /> {name}
                </a>
            );
    }
}
