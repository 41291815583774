import React from 'react';
import styles from '../home.module.css';
import Heading from 'components/typography/Heading';
import ImageWithDescription from 'components/cards/ImageWithDescription';
export default function Team() {
    return (
        <section
            id="team"
            className={`${styles.section} ${styles.teamContainer}`}
        >
            <div className={styles.container}>
                <Heading text="Meet the Team" />
                <p style={{ padding: 16 }}>
                    Founded in Australia. Building for a better future.
                </p>
                <p>
                    We were founded by <b>Onogiri</b> and <b>Dr. Dekz</b>, and
                    have been building the Meemo app for close to a year. Ono
                    has a background in computer science and cognitive sciences,
                    with extensive experience working in product development for
                    multi-billion dollar companies. Dekz is a doctor working at
                    one of Australia's leading hospitals. He has published
                    research surrounding the real world efficacy of digital
                    cognitive behavioural therapy and has worked alongside
                    research institutions pioneering global mental health
                    research.
                </p>
                <p>
                    Recently, Ono and Dekz were finalists in a Young Innovator’s
                    Competition for a large Youth Mental Health Organisation
                    based on a prototype of the Meemo app. With each team member
                    having experienced either personally or through family and
                    friends the consequences of distressing mental health, we
                    are incredibly dedicated to driving innovation in this space
                    to improve current mental health solutions.
                </p>
                <div
                    className={`${styles.teamCardsContainerSide} ${styles.teamCardsContainer}`}
                >
                    <ProfileCards members={founders} />
                </div>

                <Heading text="Core Project Team" />
                <div className={styles.teamCardsContainer}>
                    <ProfileCards members={coreProjectTeam} />
                </div>
                <Heading text="Board of Psychologists" />
                <div className={styles.teamCardsContainer}>
                    <ProfileCards members={boardOfPsychologists} />
                </div>
                <Heading text="Behind the Scene Legends" />
                <div className={styles.teamCardsContainer}>
                    <ProfileCards members={behindTheScenes} />
                </div>
            </div>
        </section>
    );
}

const founders = [
    {
        name: 'Onogiri',
        image: require('assets/team_profile/onogiri.png'),
        description:
            'Co-Founder, dehydrated stack overflow maxi. Sleeps through every whitelist.',
        twitter: 'https://twitter.com/onogiri1010'
    },
    {
        name: 'Dekz',
        image: require('assets/team_profile/dekz.png'),
        description:
            'Co-Founder, doctor by day and NFT degen by night. Type A+++ personality. ',
        twitter: 'https://twitter.com/DrDekzz'
    }
];

const coreProjectTeam = [
    {
        name: 'Jessy',
        image: require('assets/team_profile/jessy.png'),
        description:
            'Artist, loves creating and building. Sweetest person you’ll ever meet.',
        instagram: 'https://www.instagram.com/doodlepeops/'
    },
    {
        name: 'Aileen',
        image: require('assets/team_profile/aileen.png'),
        description:
            'Marketing guru, part-time vibe police. Twitter big brother.',
        twitter: 'https://twitter.com/Aileen_Shen'
    },
    {
        name: 'Chloe',
        image: require('assets/team_profile/chloe.png'),
        description:
            'Frontend developer, professional bag holder. Makes TikToks about losing money on NFTs.',
        twitter: 'https://twitter.com/cryptochloes',
        tiktok: 'https://www.tiktok.com/@cryptochloe'
    },
    {
        name: 'Mick',
        image: require('assets/team_profile/mick.png'),
        description: 'Lead Mod, resident friendly German. Has a girlfriend. ',
        twitter: 'https://twitter.com/NFTmickrich'
    }
];

const boardOfPsychologists = [
    {
        name: 'Ying',
        image: require('assets/team_profile/ying.png'),
        description:
            'Psychologist specialising in adult mood and anxiety disorders and an interest in digital psychotherapy.'
    },
    {
        name: 'Margaret',
        image: require('assets/team_profile/margaret.png'),
        description:
            'Psychologist with over 33 years of experience specialising in family and trauma therapy.'
    }
];

const behindTheScenes = [
    {
        name: 'Ryan',
        description:
            'Co-founder of an  app development company with previous indie game development experience.',
        role: 'PRODUCT DEV'
    },
    {
        name: 'Neo Legal',
        description:
            'Specialising in Australian crypto exchanges, crypto firms and crypto traders, and DeFi projects.',
        role: 'Legal'
    },
    {
        name: 'Eelin',
        description:
            'Providing consulting and accounting services and navigating cryptocurrency taxation in Singapore. ',
        role: 'Accounting'
    }
];

function ProfileCards({ members }) {
    return members.map((member, i) => {
        return <ImageWithDescription key={i} {...member} />;
    });
}
