import React from 'react';
import styles from '../home.module.css';
import { SocialButtons } from 'components/header/Header';
export default function Footer() {
    return (
        <section
            style={{ paddingBottom: 148, textAlign: 'center' }}
            className={`${styles.footer} ${styles.section}`}
        >
            <div className={styles.container}>
                <p>
                    <a href="https://quilt-laser-ce1.notion.site/Disclaimer-52991ff01cfd4b2ebe21fd17fac6e554"><u>Disclaimer</u></a>
                </p>
                <div style={{ paddingBottom: 24 }}>
                    <SocialButtons />
                </div>
                <p>Copyright © 2021 Meemo Health PTE LTD</p>
                <p>
                    Thanks and love to our supporters, our developers, our legal
                    team, accountants and moderators.
                </p>
            </div>
        </section>
    );
}
