import React from 'react';
import About from 'pages/home/sections/About';
import Application from 'pages/home/sections/Application';
import Roadmap from 'pages/home/sections/Roadmap';
import Team from 'pages/home/sections/Team';
import Footer from './sections/Footer';
import Header from 'components/header/Header';
import styles from './home.module.css';
export default function Home() {
    return (
        <div>
            <div className={styles.firstSectionContainer}>
                <Header />
                <About />
            </div>
            <Application />
            <Roadmap />
            <Team />
            <Footer />
        </div>
    );
}
