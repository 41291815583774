import React from 'react';
import styles from './navigation.module.css';
import { Links } from './Navbar';
import { SocialButtons } from 'components/header/Header';
export default function MobileNavIcon({ toggleMenu, openMenu }) {
    return (
        <div
            className={`
            ${styles.iconContainer}
            ${openMenu ? styles.open : ``}
        `}
            onClick={() => toggleMenu()}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export function MobileLinksMenu({ openMenu }) {
    return (
        <div
            className={`
            ${styles.mobileLinksContainer}
            ${openMenu ? styles.open : ``}
        `}
        >
            <div
                className={`
                ${styles.mobileLinks}
                ${openMenu ? styles.open : ``}
            `}
            >
                <Links />
                <SocialButtons />
            </div>
        </div>
    );
}
