import React from 'react';
import styles from '../home.module.css';

export default function About() {
    return (
        <section id="about" className={`${styles.section} ${styles.about}`}>
            <div className={`${styles.container}`}>
                <div className={styles.center}>
                    <p className={`${styles.textShadow}`}>
                        Meemo’s World is a collection of NFTs on the Ethereum
                        blockchain.
                    </p>
                    <p>Our vision is to:</p>
                    1. Improve accessibilty to mental health solutions <br></br>
                    2. Make taking care of your mental health FUN
                </div>
                <div
                    className={`${styles.textWithImageContainer} ${styles.imageRight}`}
                >
                    <div>
                        <p></p>
                        <h2>Mental Health App</h2>
                        <p>
                            We are the first NFT project to create a gamified,
                            community-driven mental health application centred
                            on cognitive behavioural therapy - used by
                            psychologists in the treatment of depression and
                            anxiety.
                        </p>
                    </div>
                    <div className={styles.aboutImageContainer}>
                        <img
                            alt=""
                            className={styles.rightMeemo}
                            src={require('assets/images/skyla.gif')}
                        />
                    </div>
                </div>
                <div
                    className={`${styles.textWithImageContainer} ${styles.imageLeft}`}
                >
                    <div
                        className={styles.aboutImageContainer}
                        style={{ alignItems: 'flex-end' }}
                    >
                        <img
                            alt=""
                            className={styles.rightMeemo}
                            src={require('assets/meemos/about.png')}
                        />
                    </div>
                    <div>
                        <h2>Community</h2>
                        <p>
                            The ability for genuine communities to develop
                            quickly, and the security in anonymity within the
                            space, provides unprecedented opportunity to reduce
                            stigma, create supportive networks, as well as
                            educate, discuss and share experiences surrounding
                            mental health.
                        </p>
                        <p>
                            <b>
                                <i>
                                    We want to help others, and this is our
                                    vehicle to do that in a space that we love.
                                    Meemo's World is a place where everyone can
                                    go to share their best moments, or just find
                                    a fren to talk to.
                                </i>
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
