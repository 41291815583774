import React from 'react';
import styles from './cards.module.css';
import { LogoTwitter, LogoInstagram } from 'react-ionicons';

export default function ImageWithDescription({
    image,
    role,
    name,
    description,
    twitter,
    instagram
}) {
    return (
        <div className={styles.container}>
            {image && (
                <img className={styles.circleImg} alt={name} src={image} />
            )}
            {role && <p className={styles.role}>{role}</p>}
            <p className={styles.subtitle}>{name}</p>
            <p className={styles.description}>{description}</p>
            <div className={styles.socialButtons}>
                {twitter && (
                    <a rel="noreferrer" target="_blank" href={twitter}>
                        <LogoTwitter />
                    </a>
                )}
                {instagram && (
                    <a rel="noreferrer" target="_blank" href={instagram}>
                        <LogoInstagram />
                    </a>
                )}
            </div>
        </div>
    );
}
